'use client';

import React from 'react';

import { useHeaderBanner } from '@arpia-pt-frontends/lib/hooks';
import type {
  Cms_FlyoutMenu,
  Cms_SitemapTree,
  Cms_SitemapTreeItem
} from '@arpia-pt-frontends/lib/services/cms';
import { center } from '@arpia-pt-frontends/styled-system/patterns';
import { mainNavigation } from '@arpia-pt-frontends/styled-system-web/recipes';
import * as NavRx from '@radix-ui/react-navigation-menu';

import { NavItem } from './NavItem';

// const viewportPos = cva({
//   base: {
//     pos: 'fixed',
//     display: 'flex',
//     justifyContent: 'center',
//     width: '100vw',
//     top: '60px',
//     left: 0,
//     right: 0
//   },
//   variants: {
//     isBannerActive: {
//       true: {
//         top: '94px'
//       }
//     }
//   }
// });

// const viewPort = css({
//   bg: 'white',
//   pos: 'relative',
//   transformOrigin: 'top center',
//   mt: 8,
//   w: '100%',
//   rounded: 0,
//   overflow: 'hidden',
//   boxShadow: 'rgba(100, 100, 111, 0.2) 0px 10px 20px -10px;',
//   h: 'var(--radix-navigation-menu-viewport-height)',
//   transition: 'width, height, 300ms ease;',
//   p: 6,
//   minH: '300px',

//   '&[data-state="open"]': {
//     animation: 'scaleIn 200ms ease'
//   },

//   '&[data-state="closed"]': {
//     animation: 'scaleOut 200ms ease'
//   }
// });

interface MainNavigationProps {
  pageTree?: Cms_SitemapTree;
  pageTreeItem?: Cms_SitemapTreeItem;
  flyoutMenus?: Cms_FlyoutMenu[];
  hasHeaderBanner?: boolean;
}
const MainNavigation: React.FC<MainNavigationProps> = ({
  pageTree,
  pageTreeItem,
  flyoutMenus,
  hasHeaderBanner
}) => {
  const rootItem = pageTree?.children?.find(item => item.pageId === 'home');
  const items = rootItem?.children?.filter(item => item.active && item.include.includes('header'));
  const isBannerActive = useHeaderBanner(hasHeaderBanner);
  const { viewPort, viewportPos } = mainNavigation({ isBannerActive });

  return (
    <NavRx.Root>
      <NavRx.List className={center({ listStyleType: 'none', m: 0 })}>
        {items?.map(item => (
          <NavItem
            data={item}
            key={item.id}
            flyoutMenu={flyoutMenus?.find(flm => flm.sitemapNode?.nodeId === item.id)}
            activeItemId={pageTreeItem?.id}
          />
        ))}
      </NavRx.List>
      <div className={viewportPos}>
        <NavRx.Viewport className={viewPort} />
      </div>
    </NavRx.Root>
  );
};

export { MainNavigation };
export type { MainNavigationProps };

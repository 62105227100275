'use client';

import React, { useCallback } from 'react';

import type { Cms_FlyoutMenu, Cms_SitemapTreeItem } from '@arpia-pt-frontends/lib/services/cms';
import { Container, GridItem } from '@arpia-pt-frontends/styled-system/jsx';
import { flyoutMenuSt } from '@arpia-pt-frontends/styled-system-web/recipes';
import { CmsEditorText } from '@arpia-pt-frontends/ui/cmsEditorText';
import { CmsLink, CmsNavLink } from '@arpia-pt-frontends/ui/cmsLink';
import { LayoutGrid } from '@arpia-pt-frontends/ui/layout';
import type { NavigationMenuContentProps } from '@radix-ui/react-navigation-menu';
import * as NavRx from '@radix-ui/react-navigation-menu';

import { FlyoutSlider } from './FlyoutSlider';

// const navContent = css({
//   pos: 'absolute',
//   top: 0,
//   left: 0,
//   w: '100%',
//   animationDuration: '250ms',
//   animationTimingFunction: 'ease',

//   '&[data-motion="from-start"]': {
//     animationName: 'enterFromLeft'
//   },

//   '&[data-motion="from-end"]': {
//     animationName: 'enterFromRight'
//   },

//   '&[data-motion="to-start"]': {
//     animationName: 'exitToLeft'
//   },

//   '&[data-motion="to-end"]': {
//     animationName: 'exitToRight'
//   },

//   '@media only screen and (min-width: 600px)': {
//     width: 'auto'
//   }
// });

// const navContentInner = css({
//   w: 'screen',
//   pos: 'relative'
// });

// const teaserBox = css({
//   display: 'flex',
//   flexDir: 'column',
//   borderTop: '1px solid {colors.grey.light}',
//   pt: 8,
//   pos: 'relative',
//   overflow: 'hidden'
// });

// const infoTitle = css({
//   fontFamily: 'serif',
//   fontSize: '32px',
//   lineHeight: '32.587px'
// });

// const infoText = css({
//   mt: '22px',
//   pb: 10
// });

// const menuContainer = css({
//   display: 'flex',
//   flexDir: 'column',
//   pt: 8,
//   pb: '50px',
//   borderTop: '1px solid {colors.grey.light}'
// });

// const navRubricLink = cva({
//   base: {
//     fontSize: '18px',
//     lineHeight: '18px',
//     fontWeight: 500,
//     display: 'inline-block',
//     mb: 4,
//     transition: 'colors',
//     _hover: {
//       color: 'primary'
//     }
//   },
//   variants: {
//     active: {
//       true: {
//         color: 'primary'
//       }
//     }
//   }
// });

// const navLink = cva({
//   base: {
//     fontSize: '18px',
//     lineHeight: '18px',
//     transition: 'colors',
//     _hover: {
//       color: 'primary'
//     }
//   },
//   variants: {
//     active: {
//       true: {
//         color: 'primary'
//       }
//     }
//   }
// });

// const menuList = css({
//   display: 'flex',
//   flexDir: 'column',
//   gap: 4
// });

interface FlyoutMenuProps extends NavigationMenuContentProps {
  data: Cms_SitemapTreeItem;
  flyoutMenu?: Cms_FlyoutMenu;
  activeItemId?: number;
}

const FlyoutMenu = React.forwardRef<HTMLDivElement, FlyoutMenuProps>((props, ref) => {
  const { navContent, navContentInner, teaserBox, infoTitle, infoText, menuContainer, menuList } =
    flyoutMenuSt();
  const { data, flyoutMenu, activeItemId, ...restProps } = props;
  const subNavGroups = data.children?.filter(
    item => /* item.children?.length > 0 && */ item.active
  );

  const getMenuItems = useCallback((item: Cms_SitemapTreeItem) => {
    const items = item.children?.filter(c => c.active);

    return items;
  }, []);

  return (
    <NavRx.Content className={navContent}>
      <div className={navContentInner}>
        <Container>
          <LayoutGrid gap={8} my="62px">
            {/* INFO BOX */}
            <GridItem colSpan={3} display="flex" flexDir="column" pos="relative">
              <h2 className={infoTitle}>{flyoutMenu?.displayTitle || data.title}</h2>
              <CmsEditorText data={flyoutMenu?.text} variant="blockSm" className={infoText} />
              {flyoutMenu?.link && (
                <CmsLink
                  link={flyoutMenu?.link}
                  button={{ icon: 'arrow-right', variant: flyoutMenu.link.buttonType || 'primary' }}
                />
              )}
            </GridItem>

            {/* MENU BOX */}
            <GridItem colSpan={6} pos="relative">
              <LayoutGrid gap={8} pos="relative">
                {subNavGroups?.map(item => (
                  <GridItem key={item.id} colSpan={6} className={menuContainer}>
                    <h3>
                      {/* @ts-ignore TODO FIX THIS */}
                      <NavRx.Link asChild ref={ref} {...restProps}>
                        <CmsNavLink
                          navItem={item}
                          className={
                            flyoutMenuSt({ active: activeItemId === item.id }).navRubricLink
                          }
                        />
                      </NavRx.Link>
                    </h3>
                    <ul className={menuList}>
                      {getMenuItems(item).map(child => (
                        <li key={child.id}>
                          {/* @ts-ignore TODO FIX THIS */}
                          <NavRx.Link asChild ref={ref} {...restProps}>
                            <CmsNavLink
                              navItem={child}
                              className={
                                flyoutMenuSt({ active: activeItemId === child.id }).navLink
                              }
                            />
                          </NavRx.Link>
                        </li>
                      ))}
                    </ul>
                  </GridItem>
                ))}
              </LayoutGrid>
            </GridItem>

            {/* TEASER BOX */}
            <GridItem colSpan={3} className={teaserBox}>
              {flyoutMenu && <FlyoutSlider data={flyoutMenu} />}
            </GridItem>
          </LayoutGrid>
        </Container>
      </div>
    </NavRx.Content>
  );
});
FlyoutMenu.displayName = 'FlyoutMenu';

export { FlyoutMenu };
export type { FlyoutMenuProps };

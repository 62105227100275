'use client';

import React from 'react';

import { Button } from '@arpia-pt-frontends/ui/button';

import { useTranslation } from '@/services/i18n';

const AuthLoginAction: React.FC = () => {
  const { t } = useTranslation();

  const handleLogin = () => {
    // eslint-disable-next-line no-alert
    alert('Log In!');
  };

  return (
    <Button size="small" variant="secondary" icon="arrow-right" onClick={handleLogin}>
      {t('auth.logInBtn.label')}
    </Button>
  );
};

export default AuthLoginAction;

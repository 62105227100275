'use client';

import React from 'react';

import { Button } from '@arpia-pt-frontends/ui/button';

import { useTranslation } from '@/services/i18n';

const AuthSignUpAction: React.FC = () => {
  const { t } = useTranslation();

  const handleSignUp = () => {
    // eslint-disable-next-line no-alert
    alert('Sign Up!');
  };

  return (
    <Button size="small" variant="primary" icon="arrow-right" onClick={handleSignUp}>
      {t('auth.signUpBtn.label')}
    </Button>
  );
};

export default AuthSignUpAction;

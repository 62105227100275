import {
  Cms_EditorData,
  Cms_Link,
  Cms_ResponsiveVideo,
  Cms_SingleMedia,
  Cms_TitleTag
} from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink, populateResonsiveVideo } from './populateCommon';

export type Cms_ContentHeaderVariant = 'big' | 'split_50_50' | 'colored' | 'plain';

export interface Cms_ContentHeader extends Cms_ContentSlice {
  __component: 'content.header';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  variant: Cms_ContentHeaderVariant;
  teaser?: string;
  tags?: string[];
  image?: Cms_SingleMedia;
  video?: Cms_ResponsiveVideo;
  text?: Cms_EditorData;
  links?: Cms_Link[];
}

export const populateHeader = {
  populate: {
    image: true,
    links: populateLink,
    contentBlock: true,
    video: { ...populateResonsiveVideo }
  }
};

import { useParams } from 'next/navigation';

import { PageProps, PagePropsParams } from '../services/page';

export const usePageProps = () => {
  const params = useParams() as any;
  //  const searchParams = useSearchParams();

  /* const srchParams = useMemo(() => {
    const obj: Record<string, string> = {};
    searchParams?.forEach((v, k) => {
      obj[k] = v;
    });

    return obj;
  }, [searchParams]); */

  return {
    params: params as PagePropsParams,
    searchParams: {} /* , searchParams: srchParams */
  } as PageProps;
};

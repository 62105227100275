import React from 'react';

import { BREAK_POINTS } from '@arpia-pt-frontends/lib/constants';
import { useLanguage } from '@arpia-pt-frontends/lib/hooks';
import { Cms_SearchResult } from '@arpia-pt-frontends/lib/services/cms';
import { routesService } from '@arpia-pt-frontends/lib/services/routes';
import { openInNewTab } from '@arpia-pt-frontends/lib/utils';
import { css, cx } from '@arpia-pt-frontends/styled-system/css';
import { AspectRatio, HStack, Stack } from '@arpia-pt-frontends/styled-system/jsx';
import { Icon } from '@arpia-pt-frontends/ui/icon';
import { Img } from '@arpia-pt-frontends/ui/img';
import Link from 'next/link';
import Highlighter from 'react-highlight-words';

import { useTranslation } from '@/services/i18n';

const teamPage = '/en/company/about-phoenix/team/';
const partnerPage = '/en/company/partners/';

const thumbSizes = {
  _: 64 / (BREAK_POINTS.sm / 100),
  sm: 64 / (BREAK_POINTS.sm / 100),
  md: 64 / (BREAK_POINTS.md / 100),
  lg: 64 / (BREAK_POINTS.lg / 100),
  xl: 64 / (BREAK_POINTS.xl / 100),
  '2xl': 64 / (BREAK_POINTS['2xl'] / 100)
};

const rubric = css({
  //  bg: 'primary.a10',
  fontSize: '20px',
  lineHeight: '26px',
  fontWeight: '500',
  py: 3,
  color: 'primary'
  // borderBottom: '1px solid {colors.primary.light}'
});

interface SearchResultsCategoryProps {
  data: Cms_SearchResult;
}
const SearchResultsCategory: React.FC<SearchResultsCategoryProps> = ({ data }) => {
  const prefix = window._env_?.searchPrefix || '';
  const { t } = useTranslation();

  return (
    <Stack gap={0} key={data.indexUid}>
      <div className={rubric}>{t(`search.index.${data.indexUid.replace(`${prefix}_`, '')}`)}</div>
      <Stack divideY="1px" divideColor="primary.light" gap={0} rounded={4} overflow="hidden">
        {data.hits.map(hit => (
          <SearchResult q={data.query} index={data.indexUid} data={hit} key={hit._meilisearch_id} />
        ))}
      </Stack>
    </Stack>
  );
};

const titlex = css({
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: '500',

  '& mark': {
    bg: 'transparent',
    color: 'primary'
  }
});

const textx = css({
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 'normal',

  '& mark': {
    bg: 'transparent',
    color: 'primary'
  }
});

const navIcon = css({
  w: 4,
  transform: 'translateY(-2px)',
  transition: 'transform 0.2s',
  color: 'primary',
  _groupHover: { transform: 'translate(2px, -2px)', color: 'primary.dark' }
});

const srchResult = css({
  bg: 'grey.bg',
  p: 3,
  transition: 'colors',
  _hover: { bg: 'primary.a10' }
});

interface SearchResultProps {
  index: string;
  data: any;
  q: string;
}
const SearchResult: React.FC<SearchResultProps> = ({ index, data, q }) => {
  const locale = useLanguage();
  // const { t } = useTranslation();
  let title = '';
  let hasTitleArrow = false;
  let img: React.ReactNode = null;
  let text: React.ReactNode = null;
  const prefix = window._env_?.searchPrefix || '';
  let link: string | undefined = undefined;
  let linkTarget = '_self';

  switch (index) {
    case `${prefix}_team_member`: {
      link = `${teamPage}?q=${encodeURIComponent(data.fullName)}`;
      title = data.fullName;
      img = (
        <Img
          sizes={thumbSizes}
          fill
          data={{ data: data.image }}
          className={css({ objectPosition: 'center 0' })}
        />
      );

      const texts: React.ReactNode[] = [];
      if (data.jobTitle)
        texts.push(<Highlighter searchWords={[q]} textToHighlight={data.jobTitle || ''} />);
      if (data.email) texts.push(<a href={`mailto:´${data.email}`}>{data.email}</a>);
      if (data.linkedin)
        texts.push(
          <button
            onClick={() => openInNewTab(data.linkedin)}
            className={cx('group', css({ cursor: 'pointer' }))}
          >
            <Icon
              i="linkedin"
              className={css({ w: 4, color: 'primary', _groupHover: { color: 'primary.dark' } })}
            />
          </button>
        );
      if (texts.length === 1) {
        text = texts[0];
      }
      if (texts.length > 1) {
        text = <Stack gap={0}>{texts}</Stack>;
      }

      break;
    }

    case `${prefix}_blog_post`: {
      link = routesService.getBlogPostUrl(locale, data.slugInternal); // `${blogPostPage}?q=${encodeURIComponent(q)}`;
      title = data.title;
      img = <Img sizes={thumbSizes} fill data={{ data: data.thumbnail }} />;
      text = (
        <>
          <Highlighter
            searchWords={[q]}
            textToHighlight={
              data.content.find((c: any) => c.__component === 'content.header')?.text?.textRaw || ''
            }
          />
        </>
      );

      break;
    }

    case `${prefix}_sitemap_node`: {
      title = data.title;
      hasTitleArrow = true;
      if (data.type === 'internal' && data.path) {
        link = data.path;
      }

      break;
    }

    case `${prefix}_page`: {
      title = data.sitemapNodes?.[0]?.title || data.title;
      const imgData = data.content.find((c: any) => c.__component === 'content.image')?.image;

      if (!imgData) {
        data.content.find((c: any) => c.__component === 'content.header')?.image;
      }

      if (imgData) {
        img = <Img fill data={{ data: imgData }} />;
      } else {
        img = (
          <div className={css({ bg: 'primary', color: 'white' })}>
            <Icon i="arrow-right" />
          </div>
        );
      }

      text = (
        <>
          <Highlighter
            searchWords={[q]}
            textToHighlight={
              data.content.find((c: any) => c.__component === 'content.header')?.text?.textRaw || ''
            }
          />
        </>
      );
      // console.log('SearchResult', data);
      break;
    }

    case `${prefix}_partner`: {
      link = `${partnerPage}?q=${encodeURIComponent(data.title)}`;
      title = data.title;
      text = <Highlighter searchWords={[q]} textToHighlight={data.text?.textRaw} />;
      img = (
        <Img
          sizes={thumbSizes}
          fill
          className={css({ bg: 'primary/10', p: 1 })}
          variant="contain"
          data={{ data: data.logo }}
        />
      );
    }
  }

  if (text === null && title === '' && img === null) return null;

  let gap = 0;
  if (title && (text || img)) gap = 1.5;

  const content = (
    <Stack className={srchResult} gap={gap}>
      {title &&
        (hasTitleArrow ? (
          <HStack className="group" gap={3}>
            <Icon className={navIcon} i="arrow-right" />
            <div className={titlex}>
              <Highlighter searchWords={[q]} textToHighlight={title || ''} />
            </div>
          </HStack>
        ) : (
          <div className={titlex}>
            <Highlighter searchWords={[q]} textToHighlight={title || ''} />
          </div>
        ))}
      <HStack gap={4} alignItems="flex-start">
        {img && (
          <AspectRatio
            flexShrink={0}
            rounded={4}
            overflow="hidden"
            ratio={1}
            w="60px"
            pos="relative"
          >
            {img}
          </AspectRatio>
        )}
        {text && <div className={textx}>{text}</div>}
      </HStack>
    </Stack>
  );

  if (!link) return content;

  if (linkTarget === '_self') {
    return <Link href={link}>{content}</Link>;
  }

  return (
    <a href={link} target="_blank">
      {content}
    </a>
  );
};

export { SearchResult, SearchResultsCategory };

import { Cms_Link, Cms_SitemapTreeItem } from '@arpia-pt-frontends/lib/services/cms';
import { routesService } from '@arpia-pt-frontends/lib/services/routes';

export const isLinkScroll = (link: Cms_Link, path: string): boolean => {
  if (!link._url) return true;

  return path !== routesService.addTrailingSlash(link._url);
};

export const getNavItemLinkUrl = (item: Cms_SitemapTreeItem): string | null => {
  if (item.type === 'internal') {
    return item.path || null;
  }

  if (item.type === 'external' || item.type === 'phone' || item.type === 'email') {
    let href = item.url;
    if (!href) return null;

    if (item.type === 'phone') return `tel:${item.url}`;
    if (item.type === 'email') return `mailto:${item.url}`;
  }

  return null;
};

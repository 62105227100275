import React from 'react';

import { Cms_EditorData } from '@arpia-pt-frontends/lib/services/cms';
import { HtmlTagName } from '@arpia-pt-frontends/lib/types';
import { cx } from '@arpia-pt-frontends/styled-system/css';
import {
  CmsEditorTextVariantProps,
  cmsEditorText
} from '@arpia-pt-frontends/styled-system/recipes';

import CmsEditorNode from './EditorNode';

interface CmsEditorTextProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    CmsEditorTextVariantProps {
  data?: Cms_EditorData;
  className?: string;
  isNestedLinks?: boolean;
  as?: HtmlTagName;
}

const CmsEditorText = React.forwardRef<HTMLDivElement, CmsEditorTextProps>((props, ref) => {
  const { data, className, variant = 'blockLg', isNestedLinks, as } = props;

  if (!data) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cx(cmsEditorText({ variant }), `cms-editor-text-${variant}`, className)}
    >
      {data.text?.map((node, index) => (
        <CmsEditorNode as={as} data={node} key={index} isNestedLinks={isNestedLinks} />
      ))}
    </div>
  );
});
CmsEditorText.displayName = 'CmsEditorText';

const EDITOR_TEXT_VARIANTS: CmsEditorTextVariantProps['variant'] = [
  'blockLg',
  'blockSm',
  'blockXs'
];

export { CmsEditorText, EDITOR_TEXT_VARIANTS };
export type { CmsEditorTextProps };

import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentTextImageDefaultVariants = {}
const cmsContentTextImageCompoundVariants = []

const cmsContentTextImageSlotNames = [
  [
    "container",
    "cms-teaser-big__container"
  ],
  [
    "imgBox",
    "cms-teaser-big__imgBox"
  ],
  [
    "title",
    "cms-teaser-big__title"
  ],
  [
    "text",
    "cms-teaser-big__text"
  ]
]
const cmsContentTextImageSlotFns = /* @__PURE__ */ cmsContentTextImageSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentTextImageDefaultVariants, getSlotCompoundVariant(cmsContentTextImageCompoundVariants, slotName))])

const cmsContentTextImageFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentTextImageSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentTextImageVariantKeys = []

export const cmsContentTextImage = /* @__PURE__ */ Object.assign(cmsContentTextImageFn, {
  __recipe__: false,
  __name__: 'cmsContentTextImage',
  raw: (props) => props,
  variantKeys: cmsContentTextImageVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentTextImageVariantKeys)
  },
})
/* eslint-disable react/no-unescaped-entities */
'use client';

import React, { useEffect, useRef } from 'react';

import { useLanguage, usePageProps } from '@arpia-pt-frontends/lib/hooks';
import { Cms_SearchResponse, cmsService } from '@arpia-pt-frontends/lib/services/cms';
import { css, cva } from '@arpia-pt-frontends/styled-system/css';
import { Container, HStack, Stack } from '@arpia-pt-frontends/styled-system/jsx';
import { srOnly } from '@arpia-pt-frontends/styled-system/patterns';
import { Button } from '@arpia-pt-frontends/ui/button';
import { Dialog, DialogContent } from '@arpia-pt-frontends/ui/dialog';
import { Icon } from '@arpia-pt-frontends/ui/icon';
import { ScrollArea } from '@arpia-pt-frontends/ui/scrollArea';
import { Text } from '@arpia-pt-frontends/ui/text';
import { useParams, usePathname } from 'next/navigation';

import { useTranslation } from '@/services/i18n';

import { SearchResultsCategory } from './SearchResults';

const label = css({
  '@media (max-width: 374px)': {
    display: 'none'
  },
  '@media (min-width: 1280px) and (max-width: 1390px)': {
    display: 'none'
  }
});

const dialogContent = css({
  px: 0,
  py: 8,
  top: '90px',
  bottom: 0,
  left: 0,
  right: 0,
  transform: 'none',
  w: 'screen',
  rounded: 0,
  bg: 'white',
  maxW: 'screen'
});

const overlay = css({
  top: '90px'
});

const searchInput = css({
  fontSize: '24px',
  lineHeight: '32px',
  py: 3,
  flexGrow: 1,
  bg: 'transparent',
  _focus: {
    outline: 'none'
  },
  _focusWithin: {
    ring: '1px solid {colors.primary}'
  }
});

const resetBtn = cva({
  base: {
    transform: 'opacity',
    cursor: 'pointer',
    color: 'primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: {
      color: 'primary.dark'
    },
    _focus: {
      outline: 'none'
    },
    _focusVisible: {
      ring: '1px solid {colors.primary}'
    },
    p: 1
  },
  variants: {
    text: {
      true: {
        px: '0 !important',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '400'
      }
    },
    hidden: {
      true: {
        opacity: 0,
        pointerEvents: 'none'
      }
    }
  }
});

const infoText = css({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '400',
  '& b': {
    color: 'primary',
    fontWeight: '700'
  }
});

const loader = css({
  opacity: 0,
  animation: 'fadeIn 0.3s ease-in-out forwards',
  animationDelay: '1s'
});

const SearchInner: React.FC = () => {
  const { t } = useTranslation();
  const {
    params: { tenant }
  } = usePageProps();
  const locale = useLanguage();
  const [isSearchOpen, setSearchOpen] = React.useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [result, setResult] = React.useState<Cms_SearchResponse>();
  const [isLoading, setLoading] = React.useState(false);
  const [term, setTerm] = React.useState<string>();
  const pathname = usePathname();
  const params = useParams();

  useEffect(() => {
    if (isSearchOpen) {
      if (inputRef.current) {
        inputRef.current.focus();
      }

      setTimeout(() => {
        document.body.removeAttribute('style');
      }, 1000);
    }
  }, [isSearchOpen]);

  const handleReset = () => {
    setResult(undefined);
    setTerm('');
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = e.target.value;
    setTerm(q);

    if (!q || q === '') {
      setResult(undefined);

      return;
    }

    setLoading(true);

    try {
      const res = await cmsService.search.searchContents(q, tenant, locale);
      // eslint-disable-next-line no-console
      console.log(res);
      setResult(res);
    } catch (error) {
      setResult(undefined);
    }

    setLoading(false);
  };

  useEffect(() => {
    setSearchOpen(false);
  }, [pathname, params]);

  return (
    <>
      <Button
        size="small"
        variant="tertiary"
        icon="search"
        onClick={() => setSearchOpen(!isSearchOpen)}
        className="ps-btn-search"
        noIconTransform
      >
        <span className={label}>{t('search.searchBtn.label')}</span>
        <span className={srOnly()}>{t('search.searchBtn.srLabel')}</span>
      </Button>

      <Dialog open={isSearchOpen} onOpenChange={setSearchOpen}>
        <DialogContent
          className={dialogContent}
          overlayCl={overlay}
          isCloseBtnShown={false}
          /*   onPointerDownOutside={e => e.preventDefault()}
          onOpenAutoFocus={e => e.preventDefault()} */
        >
          <Stack w="screen" h="calc(100dvh - 90px)" gap={3.5}>
            <Container maxW="1034px">
              <HStack gap={4} borderBottom="1px solid {colors.primary}" mt={6}>
                <input
                  value={term}
                  placeholder="Search"
                  ref={inputRef}
                  className={searchInput}
                  onChange={handleSearch}
                />
                <button
                  disabled={!term || term === ''}
                  className={resetBtn({ hidden: !term || term === '' })}
                  type="button"
                  onClick={handleReset}
                >
                  <Icon i="close" />
                </button>
              </HStack>
            </Container>
            {isLoading ? (
              <Container maxW="1034px">
                <Text variant="block-sm" className={loader}>
                  Search...
                </Text>
              </Container>
            ) : (
              <Stack gap={8}>
                <Container maxW="1034px">
                  <HStack justifyContent="space-between">
                    {result && (
                      <button
                        className={resetBtn({ text: true })}
                        type="button"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    )}

                    {result && result.totalHits === 0 && (
                      <Text className={infoText}>
                        No results found for <b>"{term}"</b>
                      </Text>
                    )}
                    {result && result.totalHits > 0 && (
                      <Text className={infoText}>
                        <b>{result.totalHits}</b> results found for <b>"{term}"</b>
                      </Text>
                    )}
                  </HStack>
                </Container>

                <Container maxW="1034px">
                  <ScrollArea className={css({ h: 'calc(100dvh - 280px)', mr: -2.5, pr: 2.5 })}>
                    <Stack gap={8} pb={8}>
                      {result?.results.map(res => (
                        <SearchResultsCategory data={res} key={res.indexUid} />
                      ))}
                    </Stack>
                  </ScrollArea>
                </Container>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchInner;

import React, { useMemo } from 'react';

import type {
  Cms_ContentTeam,
  Cms_SingleMedia,
  Cms_TeamMember
} from '@arpia-pt-frontends/lib/services/cms';
import { cx } from '@arpia-pt-frontends/styled-system/css';
import { Flex, HStack } from '@arpia-pt-frontends/styled-system/jsx';
import { srOnly } from '@arpia-pt-frontends/styled-system/patterns';
import { cmsContentTeam } from '@arpia-pt-frontends/styled-system-web/recipes';
import { Icon } from '@arpia-pt-frontends/ui/icon';
import { Img } from '@arpia-pt-frontends/ui/img';

// const box = css({
//   pos: 'relative'
// });

// const imageBox = css({
//   pos: 'relative',
//   bg: 'grey.bg',
//   overflow: 'hidden',
//   pt: '100%',
//   mb: '10px'
// });

// const name = css({
//   textStyle: 'block-sm',
//   fontWeight: '500'
// });

// const position = css({
//   textStyle: 'block-sm'
// });

// const link = circle({
//   border: '1px solid {colors.primary}',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   size: '38px',
//   textDecoration: 'none',
//   transition: 'colors',
//   _hover: {
//     borderColor: 'primary.dark'
//   }
// });

// const linkIcon = css({
//   w: 6,
//   h: 6,
//   color: 'primary',
//   _groupHover: {
//     color: 'primary.dark'
//   }
// });

interface TeamMemberItemProps {
  teamMember: Cms_TeamMember;
  data: Cms_ContentTeam;
  fallbackImg?: Cms_SingleMedia;
  maskingEmail?: string;
}

const TeamMemberItem: React.FC<TeamMemberItemProps> = ({
  teamMember,
  data,
  fallbackImg,
  maskingEmail
}) => {
  const { box, imageBox, name, position, link, linkIcon } = cmsContentTeam();

  const email = useMemo(() => {
    if (teamMember.maskEmail) {
      if (maskingEmail) {
        return `${maskingEmail}?subject=ATTN: ${teamMember.firstName} ${teamMember.lastName}`;
      }

      return null;
    }

    return teamMember.email;
  }, [
    maskingEmail,
    teamMember.email,
    teamMember.firstName,
    teamMember.lastName,
    teamMember.maskEmail
  ]);

  const img = useMemo(() => {
    if (teamMember.image?.data) {
      return teamMember.image;
    }
    if (data.fallbackImage?.data) {
      return data.fallbackImage;
    }

    if (fallbackImg?.data) {
      return fallbackImg;
    }

    return null;
  }, [data.fallbackImage, fallbackImg, teamMember.image]);

  return (
    <div className={box}>
      <div className={imageBox}>
        <Img fill data={img} />
      </div>
      <Flex>
        <div>
          <div className={name}>
            {teamMember.firstName} {teamMember.lastName}
          </div>
          {teamMember.jobTitle && <div className={position}>{teamMember.jobTitle}</div>}
        </div>
        <HStack ml="auto" gap={4}>
          {email && (
            <a className={cx('group', link)} href={`mailto:${email}`}>
              <Icon i="envelope" className={linkIcon} />
              <span className={srOnly()}>Send email</span>
            </a>
          )}
          {teamMember.linkedin && (
            <a
              className={cx('group', link)}
              rel="nofollow"
              target="_blank"
              href={teamMember.linkedin}
            >
              <Icon i="linkedin" className={linkIcon} />
              <span className={srOnly()}>Open linkedin profile</span>
            </a>
          )}
        </HStack>
      </Flex>
    </div>
  );
};

export { TeamMemberItem };
export type { TeamMemberItemProps };

'use client';

import React, { useMemo } from 'react';

import { Cms_SitemapTreeItem } from '@arpia-pt-frontends/lib/services/cms';
import { mobileNavigation } from '@arpia-pt-frontends/styled-system-web/recipes';
import { CmsNavLink } from '@arpia-pt-frontends/ui/cmsLink';
import { Icon } from '@arpia-pt-frontends/ui/icon';

// const navItem = css({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   w: 'full'
// });

// const expandIcon = css({
//   w: 4,
//   h: 4,
//   color: 'primary',
//   transform: 'translateY(4px)'
// });

// const navItemBtn = css({
//   outline: 'none!',
//   display: 'flex',
//   justifyContent: 'space-between',
//   w: 'full',
//   alignItems: 'center',
//   gap: 3,
//   py: 4
// });

// const labelx = cva({
//   base: {
//     fontSize: '22px',
//     lineHeight: '22px'
//   },
//   variants: {
//     active: {
//       true: {
//         color: 'primary'
//       }
//     }
//   }
// });

interface NavItemMobileProps {
  data: Cms_SitemapTreeItem;
  handleSubmenuAppereance: (item: Cms_SitemapTreeItem) => void;
  activeItemId: number | undefined;
}

const NavItemMobile: React.FC<NavItemMobileProps> = props => {
  const { navItem, expandIcon, navItemBtn } = mobileNavigation();
  const { data, handleSubmenuAppereance, activeItemId } = props;
  const hasChildren =
    data.children.length > 0 &&
    data.children.filter(item => item.active && item.include.includes('header')).length > 0;

  const iconEl = useMemo(
    () => <Icon className={expandIcon} i="chevron-right" size="small" />,
    [expandIcon]
  );

  let content = useMemo(() => {
    const label = (
      <span className={mobileNavigation({ active: data.id === activeItemId }).navItemLabel}>
        {data.title}
      </span>
    );

    if ((data.type === 'internal' || data.type === 'directory') && hasChildren) {
      return (
        <div className={navItem}>
          <button
            className={navItemBtn}
            type="button"
            onClick={() => handleSubmenuAppereance(data)}
          >
            {label}
            {iconEl}
          </button>
        </div>
      );
    }

    return (
      <div>
        <CmsNavLink navItem={data} className={navItemBtn}>
          {label}
          {iconEl}
        </CmsNavLink>
      </div>
    );
  }, [data, activeItemId, hasChildren, navItemBtn, iconEl, navItem, handleSubmenuAppereance]);

  if (!content) return null;

  return <div>{content}</div>;
};

export { NavItemMobile };
export type { NavItemMobileProps };

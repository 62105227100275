import { Cms_TitleTag } from '../../../types';
import { Cms_Partner } from '../../partners';
import { Cms_ContentSlice } from '../pages.types';

export interface Cms_ContentPartners extends Cms_ContentSlice {
  __component: 'content.partners';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  filterTags?: string[];
  showFilters: boolean;
  customSelection?: {
    data?: Cms_Partner[];
  };
}

export const populatePartners = {
  populate: {
    customSelection: {
      populate: {
        logo: true
      }
    },
    contentBlock: true
  }
};

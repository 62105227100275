import React, { useCallback, useEffect } from 'react';

import { useLanguage } from '@arpia-pt-frontends/lib/hooks';
import { cva } from '@arpia-pt-frontends/styled-system/css';
import { Autocomplete, FormControl, FormHelperText, MenuItem, TextField } from '@mui/material';
import { keys } from 'lodash';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

const opt = cva({
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    py: 1,
    px: 3,
    overflow: 'hidden',
    _hover: {
      bg: 'primary.a10',
      color: 'primary'
    }
  },
  variants: {
    selected: {
      true: {
        bg: 'primary.light',
        color: 'primary.dark'
      }
    }
  }
});

interface SelectOption<T = any> {
  value: T;
  label: string;
}

interface CountrySelectProps {
  value?: string;
  defaultValue?: string;
  onChange?: (value?: string) => void;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode | string;
  disabled?: boolean;
  name?: string;
  id?: string;
}

const CountrySelect = React.forwardRef<HTMLDivElement, CountrySelectProps>(
  ({ label, value, onChange, error, helperText, disabled, defaultValue, id, name }, compRef) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { inView, ref } = useInView();
    const locale = useLanguage();
    const [options, setOptions] = React.useState<SelectOption[]>();

    const handleChange = (e: any, newValue?: SelectOption | null) => {
      if (onChange) onChange(newValue?.value);
    };

    const loadOptions = useCallback(async () => {
      const { getName, registerLocale, getAlpha2Codes } = await import('i18n-iso-countries');
      const countriesEn = await import('i18n-iso-countries/langs/en.json');
      registerLocale(countriesEn);
      const allCountriesAlpha2 = getAlpha2Codes();
      const alpha2 = keys(allCountriesAlpha2);

      const op = alpha2.map(countryCode => ({
        value: countryCode,
        label: getName(countryCode, locale) || countryCode
      }));

      setOptions(op);
    }, [locale]);

    useEffect(() => {
      if (options || !inView) return;

      loadOptions();
    }, [inView, loadOptions, options]);

    return (
      <FormControl>
        <Autocomplete
          ref={compRef}
          value={{
            value: options?.find(o => o.value === value)?.value || '',
            label: options?.find(o => o.value === value)?.label || ''
          }}
          defaultValue={defaultValue ? options?.find(o => o.value === defaultValue) : null}
          options={options || []}
          disabled={disabled}
          ListboxProps={{ style: { maxHeight: 200 } }}
          renderInput={params => (
            <TextField
              {...params}
              id={id}
              name={name}
              disabled={disabled}
              error={error}
              label={label}
              ref={ref}
            />
          )}
          getOptionLabel={option => option.label}
          onChange={handleChange}
          renderOption={(props, option) => (
            <MenuItem component="li" {...props} className={opt()}>
              <Image
                loading="lazy"
                className="flag"
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${option.value}.svg`}
                alt={option.label}
                width={24}
                height={24}
              />
              <span>{option.label}</span>
            </MenuItem>
          )}
        />
        {helperText && (
          <FormHelperText error={error} disabled={disabled}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
CountrySelect.displayName = 'CountrySelect';

export { CountrySelect };
export type { CountrySelectProps };

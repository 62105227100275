'use client';
import React, { useEffect } from 'react';

import type { Cms_ContentPricingItem } from '@arpia-pt-frontends/lib/services/cms';
import { getTitleTag } from '@arpia-pt-frontends/lib/utils';
import { css, cx } from '@arpia-pt-frontends/styled-system/css';
import { borderBox } from '@arpia-pt-frontends/styled-system/recipes';
import { cmsContentPricing } from '@arpia-pt-frontends/styled-system-web/recipes';
import { Button } from '@arpia-pt-frontends/ui/button';
import { CmsEditorText } from '@arpia-pt-frontends/ui/cmsEditorText';
import { CmsIconClient } from '@arpia-pt-frontends/ui/cmsIcon';
import { CmsLink } from '@arpia-pt-frontends/ui/cmsLink';
import { Img } from '@arpia-pt-frontends/ui/img';
import { Text } from '@arpia-pt-frontends/ui/text';
import { useMeasure } from 'react-use';

import type { PriceItemHeight, SetPriceItemPropsHandler } from './PricingList';

// const item = cva({
//   base: {
//     bgColor: 'white',
//     display: 'flex',
//     flexDir: 'column',
//     transitionProperty: 'color',
//     transitionTimingFunction: 'colors',
//     transitionDuration: 'colors',
//     h: 'full'
//   },
//   variants: {
//     color: {
//       white: { bgColor: 'white' },
//       primary_light: {
//         bgColor: 'primary.light',
//         _hover: { borderColor: 'primary' }
//       }
//     }
//   }
// });

// const itemBox = cva({
//   base: {
//     padding: 6
//   },
//   variants: {
//     pos: {
//       title: { borderBottomWidth: 1, borderBottomColor: 'grey.light' },
//       services: { borderBottomWidth: 1, borderBottomColor: 'grey.light' },
//       button: { display: 'flex', justifyContent: 'flex-end', pt: '0' },
//       image: {},
//       price: {}
//     }
//   }
// });

// const title = css({
//   mb: 4
// });

// const services = css({ display: 'flex', flexDir: 'column', gap: '4' });

// const priceLabel = css({
//   color: 'grey.medium',
//   textStyle: 'block-xxs'
// });

// const price = css({
//   textStyle: 'block',
//   fontWeight: '500'
// });

// const service = css({ display: 'flex', gap: '10', alignItems: 'flex-start' });

// const cmsIcon = css({
//   color: 'primary',
//   flexShrink: '0'
// });

// const text = css({
//   textStyle: 'block-xs'
// });

// const imageWrap = css({
//   pos: 'relative',
//   pt: '62.5%',
//   overflow: 'hidden',
//   flex: '1',
//   borderRadius: 4
// });

// const img = css({
//   top: '0',
//   left: '0',
//   pos: 'absolute',
//   w: 'full',
//   h: 'full',
//   objectFit: 'cover'
// });

interface Props {
  data: Cms_ContentPricingItem;
  index: number;
  heights: PriceItemHeight;
  isIgnoreVisible?: boolean;
  setItemProps: SetPriceItemPropsHandler;
}

const PriceItem: React.FC<Props> = ({ data, index, heights, isIgnoreVisible, setItemProps }) => {
  const { item, itemTitle, services, priceLabel, price, service, cmsIcon, text, imageWrap, img } =
    cmsContentPricing({ color: data.color });
  // const { t } = useTranslation();
  const [titleRef, titleMeasures] = useMeasure<HTMLDivElement>();
  const [servicesRef, servicesMeasures] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    if (titleMeasures.height !== heights.titleHeight) {
      setItemProps(index, 'title', titleMeasures.height);
    }
  }, [index, heights.titleHeight, setItemProps, titleMeasures.height]);

  useEffect(() => {
    if (servicesMeasures.height !== heights.servicesHeight) {
      setItemProps(index, 'services', servicesMeasures.height);
    }
  }, [index, setItemProps, servicesMeasures.height, heights.servicesHeight]);

  const priceItemContent = (
    <div className={cx('group', borderBox(), css({ overflow: 'hidden' }))}>
      <div
        className={item}
        style={{
          visibility:
            heights.titleMinHeight === 0 && heights.servicesMinHeight === 0 && !isIgnoreVisible
              ? 'hidden'
              : 'visible'
        }}
      >
        {data.image?.data && (
          <div className={cmsContentPricing({ pos: 'image' }).itemBox}>
            <div className={imageWrap}>
              <Img fill data={data.image} alt={data.title} className={img} />
            </div>
          </div>
        )}
        <div className={cmsContentPricing({ pos: 'title' }).itemBox}>
          <div className={itemTitle}>
            <Text variant="h4" as={getTitleTag(data.titleHtmlTag) || 'h4'}>
              {data.title}
            </Text>
          </div>
          {data.text && (
            <div ref={titleRef} style={{ minHeight: heights.titleMinHeight }}>
              <CmsEditorText
                isNestedLinks={data.link?._isValid}
                data={data.text}
                variant="blockSm"
              />
            </div>
          )}
        </div>
        {data.services && data.services.length > 0 && (
          <div className={cmsContentPricing({ pos: 'services' }).itemBox}>
            <div
              className={services}
              ref={servicesRef}
              style={{ minHeight: heights.servicesMinHeight }}
            >
              {data.services?.map(s => (
                <div className={service} key={s.id}>
                  <CmsIconClient i={s.icon} className={cmsIcon} />
                  <Text variant="block-sm" className={text}>
                    {s.title}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={cmsContentPricing({ pos: 'price' }).itemBox}>
          <div className={priceLabel}>
            {data.priceLabel || 'Starting at' /* t('cmsContents.pricing.startingAt') */}
          </div>
          <div className={price}>
            {data.customPrice ? (
              data.customPrice
            ) : (
              <>
                {data.priceCurrency || 'CHF'} {data.price}
                /mo
              </>
            )}
          </div>
        </div>
        {data.link && (
          <div className={cmsContentPricing({ pos: 'button' }).itemBox}>
            {data.link && data.link._isValid && (
              <Button
                className={css({ _groupHover: { color: 'white', bg: 'primary.dark' } })}
                icon="arrow-right"
              >
                {/* t('cmsContents.pricing.startNow') */}
                {data.link.title || 'Start now'}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );

  if (data.link && data.link?._isValid) {
    return <CmsLink link={data.link}>{priceItemContent}</CmsLink>;
  }

  return priceItemContent;
};

export default PriceItem;

import { Cms_SitemapTreeItem } from '@arpia-pt-frontends/lib/services/cms';
import { WithDevtools, WithImmer } from '@arpia-pt-frontends/lib/types';
import { StoreApi, UseBoundStore, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type MobileNavType = 'mainNavigation';

export type GoogleRecaptchaIds = 'contactForm';

export interface MobileNavState {
  isOpen: boolean;
  openKeys: string[];
  type: MobileNavType | null;
  item: Cms_SitemapTreeItem | null;

  setMobileNav: (type: MobileNavType | null) => void;
  setOpenKeys: (keys: string[]) => void;
  setCurrentSubMenuContent: (item: Cms_SitemapTreeItem | null) => void;
}

const devToolsName = 'MOBILE_NAV_STORE';

export const useMobileNavStore: UseBoundStore<WithImmer<WithDevtools<StoreApi<MobileNavState>>>> =
  create<MobileNavState, [['zustand/devtools', never], ['zustand/immer', never]]>(
    devtools(
      immer(set => ({
        isOpen: false,
        openKeys: [],
        type: null,
        item: null,

        setMobileNav: type => {
          set(draft => {
            draft.type = type;
            draft.isOpen = type !== null;
          });
        },

        setOpenKeys: keys => {
          set(draft => {
            draft.openKeys = keys;
          });
        },
        setCurrentSubMenuContent: item => {
          set(draft => {
            draft.item = item;
          });
        }
      })),
      { name: devToolsName }
    )
  );

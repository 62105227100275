/* eslint-disable no-console */
'use server';

import { ResponseInfo } from '../../types';

export const getApiError = async (
  message: string,
  res?: Response | null,
  error?: unknown | null
): Promise<ResponseInfo> => {
  let errorInfo: any;

  if (res) {
    // logger.error(`API error ${message}: ${res.status} ${res.statusText}`);
    console.error(`API error ${message}: ${res.status} ${res.statusText}`);
    try {
      errorInfo = await res.json();
    } catch (e) {
      // do nothing
    }

    if (!errorInfo) {
      try {
        errorInfo = await res.text();
      } catch (e) {
        // do nothing
      }
    }
  } else if (error) {
    console.error(`API error ${message}: ${error}`);
    // logger.error(`API error ${message}: ${error}`);
    try {
      errorInfo = JSON.stringify(error);
    } catch {
      // do nothing
    }
  }

  return {
    _errorInfo: errorInfo,
    _errorMessage: message,
    _status: res?.status || 500
  };
};

import { stringify } from 'qs';

import { getApiError } from '../../../error';
import { CMS_AUTH_HEADER } from '../../configs';
import { Cms_ListResponse, Cms_QueryParams } from '../../types';

import type { Cms_FlyoutMenu } from './flyoutMenus.types';

const BASE_PATH = 'flyout-menus';

const populate = ['slides', 'slides.image', 'slides.link', 'sitemapNode', 'link'];

/**
 * GET /api/flyout-menus
 */
export const getFlyoutMenus = async (): Promise<Cms_ListResponse<Cms_FlyoutMenu>> => {
  const errorMsg = `Failed to fetch flyout menus`;
  try {
    const queryParams: Cms_QueryParams = {
      populate
    };

    const query = stringify(queryParams, { encodeValuesOnly: true });

    const res = await fetch(`${process.env.CMS_API_URL}/${BASE_PATH}?${query}`, {
      headers: CMS_AUTH_HEADER,
      next: { tags: ['cms', 'flyout-menus'] }
    });

    if (res.status !== 200) {
      return await getApiError(errorMsg, res, null);
    }

    const data = await res.json();

    return {
      ...data,
      _status: res.status
    };
  } catch (error) {
    return await getApiError(errorMsg, null, error);
  }
};

import { isServer } from '@arpia-pt-frontends/lib/utils';

import { LANGUAGES_FALLBACK } from '@/services/i18n';

export const getLanguages = () => {
  if (isServer()) {
    /**
     * TODO FIX THIS LOOKUP ON CMS LANGUAGES THEN
     */
    return [LANGUAGES_FALLBACK];
  }

  return window._env_?.languages || [LANGUAGES_FALLBACK];
};

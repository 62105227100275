'use client';

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { cx } from '@arpia-pt-frontends/styled-system/css';
import { tag, tagList } from '@arpia-pt-frontends/styled-system/recipes';

interface TagProps extends PropsWithChildren {
  className?: string;
}

const Tag = React.forwardRef<HTMLDivElement, TagProps>(({ children, className }, ref) => {
  return (
    <div ref={ref} className={cx(tag(), className)}>
      {children}
    </div>
  );
});
Tag.displayName = 'Tag';

interface TagListItem extends Omit<TagProps, 'children'> {
  label: string | React.ReactNode;
  key: string;
}

interface TagListProps {
  items: TagListItem[];
  className?: string;
}

const TagList = React.forwardRef<HTMLDivElement, TagListProps>(({ items, className }, ref) => {
  const [visibleTags, setVisibleTags] = useState<TagListItem[]>(items);
  const [isLoading, setIsLoading] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const containerRef = useRef<any>(null);
  const tagRefs = useRef<any[]>([]);
  const { list, loadingWrap, number } = tagList({ isLoading });

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const calculateVisibleTags = () => {
      if (!isClient || !items) {
        return;
      }

      const containerWidth = Math.ceil(containerRef.current?.getBoundingClientRect().width);

      let totalTagWidth = 0;
      let visibleTagCount = 0;

      for (let i = 0; i < tagRefs.current.length; i++) {
        //8 is gap width
        const tagWidth = Math.ceil(tagRefs.current[i]?.getBoundingClientRect().width || 0) + 8;

        if (totalTagWidth + tagWidth >= containerWidth) {
          break;
        }
        totalTagWidth += tagWidth;
        visibleTagCount++;
      }

      setVisibleTags(items.slice(0, visibleTagCount));
    };

    calculateVisibleTags();

    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, isClient]);

  return (
    <div className={cx(list, className)} ref={ref ? ref : containerRef}>
      <div className={loadingWrap}>
        {visibleTags.map((item, index) => (
          // TODO check this ref type issue
          <Tag key={item.key} ref={el => (tagRefs.current[index] = el) as any}>
            {item.label}
          </Tag>
        ))}
      </div>

      {items.length > visibleTags.length && (
        <div className={number}>+{items.length - visibleTags.length}</div>
      )}
    </div>
  );
});
TagList.displayName = 'TagList';

export { Tag, TagList };
export type { TagListItem, TagListProps, TagProps };

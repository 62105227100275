'use client';

import React, { useCallback, useState } from 'react';

import { useLanguage } from '@arpia-pt-frontends/lib/hooks';
import { css } from '@arpia-pt-frontends/styled-system/css';
import { HStack, Stack } from '@arpia-pt-frontends/styled-system/jsx';
import { Button } from '@arpia-pt-frontends/ui/button';
import { Form, FormField, FormInputText } from '@arpia-pt-frontends/ui/form';
import { Icon } from '@arpia-pt-frontends/ui/icon';
import { Text } from '@arpia-pt-frontends/ui/text';
import { zodResolver } from '@hookform/resolvers/zod';
import { signIn } from 'next-auth/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type FetchStatus = 'idle' | 'loading' | 'error' | 'success';

const formSchema = z.object({
  username: z
    .string({
      required_error: 'Email is required'
    })
    .email({ message: 'Invalid email' }),
  password: z.string().min(2, { message: 'Password is required' })
});

const LoginForm: React.FC = () => {
  const locale = useLanguage();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {}
  });
  const [status, setStatus] = useState<FetchStatus>('idle');

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      setStatus('loading');

      const res = await signIn('credentials', {
        username: values.username,
        password: values.password,
        redirect: false,
        callbackUrl: `${window.location.origin}/${locale}`
      });

      if (!res || res.error) {
        setStatus('error');

        return;
      }

      setStatus('success');
    },
    [locale]
  );

  return (
    <Stack
      px={{ base: 3, lg: 8 }}
      py={{ base: 4, lg: 8 }}
      gap={8}
      bg="white"
      w="full"
      mx={2}
      maxW={'600px'}
      rounded="md"
    >
      <Text variant="h2">Login</Text>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack gap={8}>
            <Stack gap={5}>
              <FormField
                name="username"
                control={form.control}
                render={({ field }) => (
                  <FormInputText label="Email" required field={field as any} />
                )}
              />
              <FormField
                name="password"
                control={form.control}
                render={({ field }) => (
                  <FormInputText type="password" label="Password" field={field as any} />
                )}
              />
            </Stack>
            {status === 'error' && (
              <HStack
                bg="red.50"
                p={3}
                border="1px solid {colors.red.600}"
                rounded="sm"
                color="red.600"
              >
                <Icon i="exclamation-circle" />
                <p>Email or password invalid</p>
              </HStack>
            )}
            {status === 'success' && (
              <HStack
                gap={3}
                bg="green.50"
                p={3}
                border="1px solid {colors.green.600}"
                rounded="sm"
                color="green.600"
              >
                <Icon i="check-circle" />
                <p>Authentication successful</p>
              </HStack>
            )}
            <Button
              type="submit"
              size="default"
              disabled={status === 'loading'}
              className={css({ alignSelf: 'flex-start' })}
              icon="arrow-right"
              loading={status === 'loading'}
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Form>
    </Stack>
  );
};

export default LoginForm;

/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import React, { useEffect, useMemo, useState } from 'react';

import type { Cms_ContentVideo } from '@arpia-pt-frontends/lib/services/cms';
import { useTranslation } from '@arpia-pt-frontends/lib/services/i18n';
import { cx } from '@arpia-pt-frontends/styled-system/css';
import { Center } from '@arpia-pt-frontends/styled-system/jsx';
import { srOnly } from '@arpia-pt-frontends/styled-system/patterns';
import { cmsContentVideo } from '@arpia-pt-frontends/styled-system-web/recipes';
import { Icon } from '@arpia-pt-frontends/ui/icon';
import { Img } from '@arpia-pt-frontends/ui/img';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';

const ReactPlayer = dynamic(() => import('react-player/lazy'));

// const box = css({
//   pos: 'relative',
//   w: 'full'
// });

// const playerBox = css({
//   pos: 'absolute',
//   inset: 0
// });

// const video = css({
//   pos: 'relative',
//   w: 'full',
//   h: 'full'
// });

// const playIconBox = css({
//   pos: 'absolute',
//   border: '1px solid {colors.primary}',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   transition: 'colors',
//   rounded: 25,
//   bg: 'white',
//   px: '36px',
//   gap: '10px',
//   h: '50px',
//   _hover: {
//     borderColor: 'primary.dark'
//   }
// });

// const playBtnLabel = css({
//   textStyle: 'block-sm',
//   fontWeight: '400',
//   lineHeight: 'normal',
//   textAlign: 'center',
//   transition: 'colors',
//   color: 'primary',
//   display: { base: 'none', md: 'block' },
//   _groupHover: {
//     borderColor: 'primary.dark'
//   }
// });

// const playBtnIcon = css({
//   color: 'primary',
//   transition: 'all',
//   _groupHover: {
//     borderColor: 'primary.dark',
//     transform: 'translateX(2px)'
//   }
// });

// const videoOverlay = cva({
//   base: {
//     pos: 'absolute',
//     top: 0,
//     left: 0
//   },
//   variants: {
//     isPlayerMounted: {
//       true: {
//         animation: 'fadeOut 0.2s forwards',
//         animationDelay: '500ms'
//       }
//     }
//   }
// });

interface Props {
  data: Cms_ContentVideo;
}

const Video: React.FC<Props> = ({ data }) => {
  const [shouldPlayVideo, setPlayVideo] = useState(false);
  const [isPlayerMounted, setPlayerMounted] = useState(false);
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 1
  });
  const { box, playerBox, video, playIconBox, playBtnLabel, playBtnIcon, videoOverlay } =
    cmsContentVideo({ isPlayerMounted });

  const ratio = useMemo(() => {
    if (data.video.ratio && data.video.ratio !== 0) {
      return (1 / data.video.ratio) * 100;
    }

    return (9 / 16) * 100;
  }, [data.video.ratio]);

  const playBtn = (
    <div className={cx(playIconBox, 'group')}>
      {data.playButtonLabel && <div className={playBtnLabel}>{data.playButtonLabel}</div>}
      <Icon i="play" className={playBtnIcon} />
    </div>
  );

  useEffect(() => {
    if (inView) {
      setPlayerMounted(true);
    }
  }, [inView]);

  useEffect(() => {
    if (data.video.autoplay) {
      setPlayVideo(true);
    }
  }, [data.video.autoplay]);

  return (
    <div ref={ref} style={{ paddingTop: `${ratio}%` }} className={box}>
      <div className={playerBox}>
        <div className={cx(video, videoOverlay)}>
          <Img fill data={data.previewImage} />
          <Center pos="absolute" inset={0} zIndex={1}>
            {playBtn}
          </Center>
        </div>

        {isPlayerMounted && (
          <ReactPlayer
            className={video}
            url={data.video.url}
            width="100%"
            height="100%"
            loop={data.video.loop}
            volume={0.2}
            muted={data.video.autoplay}
            pip
            playing={shouldPlayVideo}
            controls={data.video.controls}
            onClickPreview={() => setPlayVideo(true)}
            light={
              data.previewImage ? (
                <div className={video}>
                  <Img fill data={data.previewImage} />
                </div>
              ) : (
                true
              )
            }
            playIcon={
              <div className={cx(playIconBox, 'group')}>
                {data.playButtonLabel && <div className={playBtnLabel}>{data.playButtonLabel}</div>}
                <Icon i="play" className={playBtnIcon} />
                <div className={srOnly()}>{t('cmsContent.video.playVideo')}</div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default Video;

/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import React from 'react';

import { BREAK_POINTS } from '@arpia-pt-frontends/lib/constants';
import { imageService } from '@arpia-pt-frontends/lib/services/image';
import { BreakPointKey } from '@arpia-pt-frontends/lib/types';
import { cx } from '@arpia-pt-frontends/styled-system/css';
import { ImageVariantProps, image } from '@arpia-pt-frontends/styled-system/recipes';
import { keys } from 'lodash';
import type { ImageProps as NextImageProps } from 'next/image';
import type { ImageLoader } from 'next/image';
import Image from 'next/image';

interface ImgWidths {
  _?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  '2xl'?: number;
  '3xl'?: number;
  '4xl'?: number;
  '5xl'?: number;
  '6xl'?: number;
}

interface ImgProps extends Omit<NextImageProps, 'alt' | 'sizes' | 'src'>, ImageVariantProps {
  alt?: string;
  fallbackAlt?: string;
  data?: any; // Cms_SingleMedia;
  transform?: any;
  sizes?: any; // ImgSizes;
  widths?: ImgWidths;
  resize?: {
    fit?: 'contain' | 'cover';
    withoutEnlargement?: boolean;
  };
}

const Img = React.forwardRef<HTMLImageElement, ImgProps>((props, ref) => {
  const {
    resize,
    transform,
    loader,
    width,
    height,
    alt,
    data,
    fallbackAlt,
    widths,
    className,
    sizes,
    fill,
    variant,
    ...restProps
  } = props;

  const imageLoader: ImageLoader = loaderProps => {
    const tr = {
      ...transform,
      resize: {
        width: loaderProps.width,
        fit: resize?.fit || 'cover',
        withoutEnlargement: resize?.withoutEnlargement || true,
        background: 'transparent'
      }
    };

    return imageService.getImgUrlFromKey(loaderProps.src, tr);
  };

  const getSizes = () => {
    if (width && height && !sizes && !widths) return undefined;

    let calcSizes: string[] = [];

    (keys(props.sizes) as BreakPointKey[]).forEach(key => {
      const val = props.sizes?.[key];

      if (val) {
        calcSizes.push(`(min-width: ${BREAK_POINTS[key]}px) ${val}vw`);
      }
    });

    if (!widths?._) {
      calcSizes.push('100vw');
    }

    return calcSizes.join(', ');
  };

  if (!data || !data.data) return null;

  return (
    <Image
      ref={ref}
      className={cx('img', image({ variant: fill ? variant || 'cover' : undefined }), className)}
      sizes={getSizes()}
      width={width}
      height={height}
      loader={loader || imageLoader}
      src={imageService.getKeyFormS3Url(data.data?.url)}
      alt={alt || data.data.alternativeText || fallbackAlt || 'PHOENIX-SYSTEMS'}
      fill={fill}
      {...restProps}
    />
  );
});
Img.displayName = 'Img';

export { Img };
export type { ImgProps, ImgWidths };

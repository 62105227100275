import { Cms_EditorData, Cms_SingleMedia, Cms_TitleTag } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

interface Cms_ContentContactFormAddress {
  id: number;
  address: Cms_EditorData;
  phone: string;
  email: string;
  image: Cms_SingleMedia;
}

export interface Cms_ContentContactForm extends Cms_ContentSlice {
  __component: 'content.contact-form';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  teaser?: string;
  text?: Cms_EditorData;
  address?: Cms_ContentContactFormAddress;
  bgImage?: Cms_SingleMedia;
  context?: string;
}

export const populateContactForm = {
  populate: {
    bgImage: true,
    contentBlock: true,
    address: {
      populate: {
        image: true
      }
    }
  }
};

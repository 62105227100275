import { getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentBrandsBannerDefaultVariants = {}
const cmsContentBrandsBannerCompoundVariants = []

const cmsContentBrandsBannerSlotNames = [
  [
    "box",
    "cms-brands-banner__box"
  ],
  [
    "imgWrap",
    "cms-brands-banner__imgWrap"
  ],
  [
    "list",
    "cms-brands-banner__list"
  ]
]
const cmsContentBrandsBannerSlotFns = /* @__PURE__ */ cmsContentBrandsBannerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentBrandsBannerDefaultVariants, getSlotCompoundVariant(cmsContentBrandsBannerCompoundVariants, slotName))])

const cmsContentBrandsBannerFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentBrandsBannerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn(props)]))
})

const cmsContentBrandsBannerVariantKeys = []

export const cmsContentBrandsBanner = /* @__PURE__ */ Object.assign(cmsContentBrandsBannerFn, {
  __recipe__: false,
  __name__: 'cmsContentBrandsBanner',
  raw: (props) => props,
  variantKeys: cmsContentBrandsBannerVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentBrandsBannerVariantKeys)
  },
})
/**
 * https://www.meilisearch.com/docs/reference/api/overview
 */
'use server';

import { Locale } from '../../../i18n';

import searchClient from './client';
import { Cms_SearchResponse, Cms_SearchResult } from './search.types';

const pre = process.env.MEILISEARCH_INDEX_PREFIX || '';

const indexes = ['sitemap_node', 'partner', 'team_member', 'blog_post', 'page'];

export const searchContents = async (
  q: string,
  tenantUid: string, // Tenant_Uid,
  locale: Locale
): Promise<Cms_SearchResponse> => {
  const response = await searchClient.multiSearch({
    queries: indexes.map(index => ({
      indexUid: `${pre}_${index}`,
      q
    }))
  });

  const results: Cms_SearchResult[] = [];
  let totalHits = 0;

  response.results.forEach(result => {
    const hits = result.hits.filter(h => h.tenants?.includes(tenantUid) && h.locale === locale);
    totalHits += hits.length;
    if (hits.length > 0) {
      results.push({ ...result, hits });
    }
  });

  return {
    results,
    totalHits
  };
};

'use client';

import React from 'react';

import type { Cms_FlyoutMenu, Cms_SitemapTreeItem } from '@arpia-pt-frontends/lib/services/cms';
import { mobileNavigation } from '@arpia-pt-frontends/styled-system-web/recipes';
import { CmsNavLink } from '@arpia-pt-frontends/ui/cmsLink';

import { FlyoutSlider } from '@arpia-pt-frontends/ui-web/flyoutMenu';

// const navLink = cva({
//   base: {
//     fontSize: '18px',
//     lineHeight: '18px',
//     _focus: {
//       color: 'primary'
//     }
//   },
//   variants: {
//     active: {
//       true: {
//         color: 'primary'
//       }
//     },
//     isTopLevel: {
//       true: {
//         fontSize: '18px',
//         fontWeight: '500',
//         lineHeight: '18px'
//       }
//     }
//   }
// });

// const menuList = cva({
//   base: {
//     display: 'flex',
//     flexDir: 'column',
//     gap: 4,
//     listStyleType: 'none',
//     m: 0,
//     pt: 4,
//     pb: { base: 8, md: 0 }
//   },
//   variants: {
//     isTopLevel: {
//       true: {
//         pt: 0,
//         flexDir: { base: 'column', md: 'row' },
//         gap: { md: 8 },
//         flexWrap: { md: 'wrap' },
//         pos: 'relative'
//       },
//       false: {
//         pt: 4,
//         borderBottom: { base: '1px solid {colors.grey.light}', md: 'none' }
//       }
//     }
//   }
// });

// const menuListItem = cva({
//   base: {
//     listStyleType: 'none'
//   },
//   variants: {
//     isTopLevel: {
//       true: {
//         pt: { base: 8, md: 8 },
//         borderTop: { md: '1px solid {colors.grey.light}' },
//         minW: { md: '366px' }
//       }
//     }
//   }
// });

// const teaserBox = css({
//   display: 'flex',
//   flexDir: 'column',
//   py: 4,
//   pos: 'relative',
//   overflow: 'hidden',
//   maxW: { md: '366px' },
//   pt: { md: 8 },
//   mt: { md: 16 },
//   borderTop: { md: '1px solid {colors.grey.light}' },
//   // tood check if needed on physical mobiles
//   mb: 16
// });

type MobileSubItemsProps = {
  data: Cms_SitemapTreeItem | Cms_SitemapTreeItem[] | null;
  depth?: number;
  activeItemId?: number | undefined;
  flyoutMenus?: Cms_FlyoutMenu[];
  itemId?: number;
};

const MobileSubItems: React.FC<MobileSubItemsProps> = ({
  data,
  depth = 0,
  activeItemId,
  flyoutMenus,
  itemId
}) => {
  const isTopLevel = depth === 1;
  const { menuList, menuListItem, teaserBox } = mobileNavigation({ isTopLevel });

  if (!data) return null;

  const flyoutMenu = flyoutMenus?.find(flm => flm.sitemapNode?.nodeId === itemId);

  if (Array.isArray(data)) {
    return (
      <>
        {data.filter(item => item.include?.includes('header')).length > 0 && (
          <ul className={menuList}>
            {data
              .filter(item => item.include?.includes('header'))
              .map(item => (
                <li className={menuListItem} key={item.id}>
                  {/* {linkContent(item)} */}
                  <CmsNavLink
                    navItem={item}
                    className={
                      mobileNavigation({ active: activeItemId === item.id, isTopLevel }).navLink
                    }
                  />

                  {item.children?.filter(c => c.active).length > 0 && (
                    <MobileSubItems
                      data={item.children?.filter(c => c.active)}
                      depth={depth + 1}
                      activeItemId={activeItemId}
                      itemId={itemId}
                      flyoutMenus={flyoutMenus}
                    />
                  )}
                </li>
              ))}
          </ul>
        )}
        {depth === 1 && flyoutMenu && (
          <div className={teaserBox}>
            <FlyoutSlider data={flyoutMenu} isMobile />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {data.children?.filter(c => c.active).length > 0 && (
        <MobileSubItems
          data={data.children?.filter(c => c.active)}
          depth={depth + 1}
          activeItemId={activeItemId}
          itemId={itemId}
          flyoutMenus={flyoutMenus}
        />
      )}
    </div>
  );
};

export { MobileSubItems };
export type { MobileSubItemsProps };

import { Cms_TitleTag } from '../../../types';
import { Cms_BlogPost } from '../../blogPosts';
import { Cms_ContentSlice } from '../pages.types';

export type Cms_ContentBlogPostsVariant = 'grid' | 'slider';

export interface Cms_ContentBlogPosts extends Cms_ContentSlice {
  __component: 'content.blog-posts';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  filterTags?: string[];
  showFilters: boolean;
  customSelection?: {
    data?: Cms_BlogPost[];
  };
}

export const populateBlogPosts = {
  populate: {
    customSelection: {
      populate: {
        thumbnail: true
      }
    },
    contentBlock: true
  }
};

/**
 * https://ui.shadcn.com/docs/components/scroll-area
 *
 * radix: https://www.radix-ui.com/primitives/docs/components/scroll-area
 */
'use client';

import * as React from 'react';

import { cx } from '@arpia-pt-frontends/styled-system/css';
import { scrollArea } from '@arpia-pt-frontends/styled-system/recipes';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    viewportCl?: string;
    scrollbarCl?: string;
    orientation?: 'vertical' | 'horizontal';
    thumbCl?: string;
  }
>(
  (
    { className, children, viewportCl, orientation = 'vertical', scrollbarCl, thumbCl, ...rest },
    ref
  ) => {
    const { root, viewport } = scrollArea({ orientation });

    return (
      <ScrollAreaPrimitive.Root ref={ref} className={cx(root, className)} {...rest}>
        <ScrollAreaPrimitive.Viewport className={cx(viewport, viewportCl)}>
          {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar className={scrollbarCl} orientation={orientation} thumbCl={thumbCl} />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    );
  }
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar> & {
    thumbCl?: string;
  }
>(({ className, orientation = 'vertical', thumbCl, ...props }, ref) => {
  const { scrollbar, thumb } = scrollArea({ orientation });
  
return (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cx(scrollbar, className)}
      {...props}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb className={cx(thumb, thumbCl)} />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  );
});
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };

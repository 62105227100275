'use client';

import type { CSSProperties } from 'react';
import React, { useCallback, useEffect } from 'react';

import { useIsMobile } from '@arpia-pt-frontends/lib/hooks';
import { css } from '@arpia-pt-frontends/styled-system/css';
import type { AnimatedProps } from '@react-spring/web';
import { animated, useTransition } from '@react-spring/web';
import { useWindowScroll, useWindowSize } from 'react-use';

import EcosystemLastLayer from './assets/EcosystemLastLayer';
import EcosystemMiddleLayer from './assets/EcosystemMiddleLayer';
import EcosystemTopLayer from './assets/EcosystemTopLayer';

type ImageLayer = 1 | 2 | 3;

interface Props {
  setActiveLayer: React.Dispatch<React.SetStateAction<ImageLayer>>;
  activeLayer: ImageLayer;
}

const imageBox = css({
  pos: 'relative',
  minH: '280vh',

  '@media (max-width: 1000px)': {
    w: 'auto',
    display: 'inline-block'
  },

  '& svg': {
    pos: 'sticky',
    top: { lg: '110px', xl: '160px' },
    maxH: 'calc(100vh - 180px)',
    mx: 'auto'
  }
});

const EcosystemParallaxContainer: React.FC<Props> = ({ setActiveLayer, activeLayer }) => {
  const isMobile = useIsMobile();
  const windowSize = useWindowSize();
  const ref = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const wScroll = useWindowScroll();

  const calculateLayer = useCallback(() => {
    if (!ref.current || !containerRef.current || isMobile) return;

    const scrollStart = containerRef.current.getBoundingClientRect().top + wScroll.y;
    const scrollEnd = scrollStart + containerRef.current.getBoundingClientRect().height - 500;
    const layerSection = (scrollEnd - scrollStart) / 3;

    let layer: ImageLayer = 1;

    if (wScroll.y > scrollStart + layerSection) {
      layer = 2;
    }
    if (wScroll.y > scrollStart + 2 * layerSection) {
      layer = 3;
    }

    setActiveLayer(layer);
  }, [isMobile, setActiveLayer, wScroll.y]);

  useEffect(() => {
    calculateLayer();
  }, [wScroll, windowSize, calculateLayer]);

  const LayerItems: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
    ({ style }) => (
      <animated.div
        style={{ ...style, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <EcosystemTopLayer />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div
        style={{ ...style, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <EcosystemMiddleLayer />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div
        style={{ ...style, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <EcosystemLastLayer />
      </animated.div>
    )
  ];

  const transitions = useTransition(activeLayer, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 }
  });

  return (
    <div className={imageBox} ref={containerRef}>
      <div ref={ref}>
        {transitions((style, i) => {
          const Layeritem = LayerItems[i - 1];

          if (!Layeritem) return null;

          return <Layeritem style={style} />;
        })}
      </div>
    </div>
  );
};

export default EcosystemParallaxContainer;

'use client';

import React from 'react';

import type { Cms_ContentListItem } from '@arpia-pt-frontends/lib/services/cms';
import { cmsContentList } from '@arpia-pt-frontends/styled-system-web/recipes';
import { CmsEditorText } from '@arpia-pt-frontends/ui/cmsEditorText';
import { Icon } from '@arpia-pt-frontends/ui/icon';

interface Props {
  data: Cms_ContentListItem;
}

// const container = css({
//   display: 'flex',
//   alignItems: 'flex-start',
//   gap: { base: 4, md: 8 },
//   py: { base: 4, md: 6 },
//   borderTop: '1px solid {colors.grey.light}'
// });

// const icon = css({
//   w: 6,
//   h: 6,
//   color: 'primary'
// });

const ContentListItem: React.FC<Props> = ({ data }) => {
  const { container, icon } = cmsContentList();

  return (
    <div className={container}>
      <Icon i={'check-circle'} className={icon} />

      <CmsEditorText variant="blockLg" data={data.text} />
    </div>
  );
};

export default ContentListItem;

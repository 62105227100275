'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useIsMobile, usePageProps } from '@arpia-pt-frontends/lib/hooks';
import { sendContactRequest } from '@arpia-pt-frontends/lib/server-actions';
import { Cms_ContentContactForm } from '@arpia-pt-frontends/lib/services/cms';
import { useTranslation } from '@arpia-pt-frontends/lib/services/i18n';
import { Tenant_Uid } from '@arpia-pt-frontends/lib/services/tenant';
import { getTitleTag } from '@arpia-pt-frontends/lib/utils';
import { Center, Flex, Grid, GridItem, VStack } from '@arpia-pt-frontends/styled-system/jsx';
import { cmsContentContactForm } from '@arpia-pt-frontends/styled-system-web/recipes';
import { Button } from '@arpia-pt-frontends/ui/button';
import { CmsEditorText } from '@arpia-pt-frontends/ui/cmsEditorText';
import { Form, FormField, FormInputText } from '@arpia-pt-frontends/ui/form';
import { Icon } from '@arpia-pt-frontends/ui/icon';
import { Img } from '@arpia-pt-frontends/ui/img';
import { CountrySelect } from '@arpia-pt-frontends/ui/select';
import { Text } from '@arpia-pt-frontends/ui/text';
import { zodResolver } from '@hookform/resolvers/zod';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface ContactFormProps {
  data: Cms_ContentContactForm;
  formId: string;
}

type FetchStatus = 'idle' | 'loading' | 'error' | 'success';

// const teaser = css({
//   mb: '14px'
// });

// const title = css({
//   my: 6
// });

// const formWrapper = css({
//   pt: 8
// });

// const imgBox = css({
//   h: '384px',
//   w: 'full',
//   pos: 'relative'
// });

// const infoIcon = css({
//   w: 6,
//   h: 6,
//   transform: 'translateY(5px)'
// });

// const infoText = css({
//   '& a': {
//     color: 'black',
//     border: 'none!',
//     _hover: {
//       color: 'primary'
//     },
//     _focus: {
//       color: 'primary'
//     }
//   }
// });

// const resIcon = css({
//   w: 6,
//   h: 6,
//   color: 'primary'
// });

// const buttons = css({
//   display: 'flex'
// });

// const resMsg = css({
//   textAlign: 'center'
// });

const ContactForm: React.FC<ContactFormProps> = ({ data, formId }) => {
  const {
    containerInner,
    teaser,
    title,
    formWrapper,
    imgBox,
    infoIcon,
    infoText,
    resIcon,
    resMsg,
    buttons,
    gridItem,
    gridItemAddr
  } = cmsContentContactForm();
  const {
    params: { tenant }
  } = usePageProps();
  const pageProps = usePageProps();
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState<string | null>();
  const catpchaRef = useRef<ReCAPTCHA>(null);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [siteKey, setSiteKey] = useState<string | undefined>(undefined);

  const formSchema = z.object({
    email: z
      .string({
        required_error: t('contact.field.fieldRquired', { name: t('contact.field.email') })
      })
      .email({ message: 'Invalid email' }),
    name: z.string().max(100).optional(),
    topic: z.string().max(100).optional(),
    message: z
      .string({
        required_error: t('contact.field.fieldRquired', { name: t('contact.field.message') })
      })
      .min(1)
      .max(2000),
    country: z.string().max(2).optional()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {}
  });

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      if (!googleRecaptchaToken) return;

      setStatus('loading');
      const isSuccess = await sendContactRequest(
        pageProps,
        values,
        window.location.href,
        tenant as Tenant_Uid,
        googleRecaptchaToken,
        data.context
      );
      if (!isSuccess) {
        setStatus('error');

        return;
      }

      setStatus('success');
    },
    [data.context, googleRecaptchaToken, pageProps, tenant]
  );

  const handleCaptcha = (token: string | null) => {
    setGoogleRecaptchaToken(token);
  };

  const handleReset = () => {
    setStatus('idle');
    form.reset();
  };

  useEffect(() => {
    setStatus('idle');
  }, []);

  useEffect(() => {
    if (!isMobile || status !== 'success') return;

    const el = document.getElementById(formId);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  }, [formId, isMobile, status]);

  useEffect(() => {
    setSiteKey(window._env_?.captchaSiteKey);
  }, []);

  return (
    <Grid gap={8} columns={{ base: 1, lg: 2 }} className={containerInner}>
      <GridItem id={formId} className={gridItem}>
        {status !== 'success' && status !== 'error' && (
          <>
            <Text className={teaser} variant="teaser">
              {data.teaser}
            </Text>
            <Text className={title} variant="h2" as={getTitleTag(data.titleHtmlTag, 'h2')}>
              {data.title}
            </Text>
            <CmsEditorText variant="blockLg" data={data.text} />

            <div className={formWrapper}>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <Flex gap={4} flexDir="column">
                    <Grid gap={4} columns={{ base: 1, md: 2 }}>
                      <FormField
                        name="email"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            isMui
                            label={t('contact.field.email')}
                            required
                            field={field as any}
                            {...field}
                          />
                        )}
                      />
                      <FormField
                        name="name"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            field={field as any}
                            isMui
                            label={t('contact.field.name')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid gap={4} columns={{ base: 1, md: 2 }}>
                      <FormField
                        name="country"
                        control={form.control}
                        render={({ field }) => (
                          <CountrySelect label={t('contact.field.country')} {...field} />
                        )}
                      />
                      <FormField
                        name="topic"
                        control={form.control}
                        render={({ field }) => (
                          <FormInputText
                            field={field as any}
                            isMui
                            label={t('contact.field.topic')}
                          />
                        )}
                      />
                    </Grid>

                    <FormField
                      name="message"
                      control={form.control}
                      render={({ field }) => (
                        <FormInputText
                          label={t('contact.field.message')}
                          required
                          multiLine
                          isMui
                          rows={4}
                          field={field as any}
                          {...field}
                        />
                      )}
                    />

                    {siteKey && (
                      <ReCAPTCHA sitekey={siteKey} onChange={handleCaptcha} ref={catpchaRef} />
                    )}

                    <div className={buttons}>
                      <Button
                        size="default"
                        variant="primary"
                        icon="arrow-right"
                        type="submit"
                        loading={status === 'loading'}
                        disabled={status === 'loading' || googleRecaptchaToken === undefined}
                      >
                        {t('contact.btn.label')}
                      </Button>
                    </div>
                  </Flex>
                </form>
              </Form>
            </div>
          </>
        )}

        {status === 'success' && (
          <Center h="full">
            <VStack>
              <Icon i="check-circle" className={resIcon} />
              <div className={title}>
                <Text variant="block" className={resMsg}>
                  {t('contact.success')}
                </Text>
                <Text variant="block" className={resMsg}>
                  {t('contact.successTwo')}
                </Text>
              </div>
              <Button size="default" variant="secondary" onClick={handleReset}>
                {t('contact.succBtn.label')}
              </Button>
            </VStack>
          </Center>
        )}

        {status === 'error' && (
          <Center h="full">
            <VStack>
              <Icon i="flag-exclamation" className={resIcon} />
              <div className={title}>
                <Text variant="block" className={resMsg}>
                  {t('contact.error')}
                </Text>
                <Text variant="block" className={resMsg}>
                  {t('contact.errorTwo')}
                </Text>
              </div>
              <Button size="default" variant="secondary" onClick={handleReset}>
                {t('contact.succBtn.label')}
              </Button>
            </VStack>
          </Center>
        )}
      </GridItem>

      {data.address && (
        <GridItem className={gridItemAddr}>
          <div className={imgBox}>
            <Img fill data={data.address.image} alt={data.title} />
          </div>
          <Flex flexDir="column" gap={6} p={8}>
            <Flex gap={8}>
              <Icon i="pin-location" className={infoIcon} />
              <CmsEditorText data={data.address.address} variant="blockLg" className={infoText} />
            </Flex>
            <Flex gap={8}>
              <Icon i="phone" className={infoIcon} />
              <Text variant="block" className={infoText}>
                <a href={`tel:${data.address.phone}`}>{data.address.phone}</a>
              </Text>
            </Flex>
            <Flex gap={8}>
              <Icon i="envelope" className={infoIcon} />
              <Text variant="block" className={infoText}>
                <a href={`mailto:${data.address.email}`}>{data.address.email}</a>
              </Text>
            </Flex>
          </Flex>
        </GridItem>
      )}
    </Grid>
  );
};

export { ContactForm };
export type { ContactFormProps };

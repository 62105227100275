'use client';

import React from 'react';

import { usePageProps } from '@arpia-pt-frontends/lib/hooks';
import type { Cms_BlogPost } from '@arpia-pt-frontends/lib/services/cms';
import { routesService } from '@arpia-pt-frontends/lib/services/routes';
import { cx } from '@arpia-pt-frontends/styled-system/css';
import { AspectRatio } from '@arpia-pt-frontends/styled-system/jsx';
import { borderBox } from '@arpia-pt-frontends/styled-system/recipes';
import { cmsContentBlogPosts } from '@arpia-pt-frontends/styled-system-web/recipes';
import { Button } from '@arpia-pt-frontends/ui/button';
import { Img } from '@arpia-pt-frontends/ui/img';
import { TagList } from '@arpia-pt-frontends/ui/tag';
import { Text } from '@arpia-pt-frontends/ui/text';
import Link from 'next/link';

interface Props {
  data: Cms_BlogPost;
}

// const item = css({
//   p: 6,
//   display: 'flex',
//   flexDirection: 'column',
//   minH: '498px',
//   h: 'full',
//   transition: 'colors'
// });

// const button = css({
//   _groupHover: {
//     color: 'primary.dark'
//   }
// });

// const tagList = css({
//   mb: 6
// });

// const img = css({
//   bg: 'primary.a10',
//   rounded: 4,
//   overflow: 'hidden'
// });

// const itemTitle = css({
//   mt: 8
// });

// const itemLink = css({
//   mt: 6,
//   flexGrow: 1,
//   display: 'flex',
//   alignItems: 'flex-end',
//   justifyContent: 'flex-end'
// });

const BlogPostItem: React.FC<Props> = ({ data }) => {
  const { item, button, img, itemTitle, itemLink, tagList } = cmsContentBlogPosts();
  //  const { t } = useTranslation();
  const pageProps = usePageProps();
  const link = routesService.getBlogPostUrl(pageProps.params.lng, data.slug?.slug);

  const content = () => {
    return (
      <div className={cx('group', borderBox(), item)}>
        <div className={tagList}>
          <TagList
            items={
              data.tags
                ?.sort((a, b) => a.localeCompare(b))
                .map(tag => ({ key: tag, label: tag })) || []
            }
          />
        </div>
        {data.thumbnail?.data && (
          <AspectRatio ratio={1.6} className={img}>
            <Img fill data={data.thumbnail} />
          </AspectRatio>
        )}
        <Text variant="h5" as="h4" className={itemTitle}>
          {data.title}
        </Text>

        {link && (
          <div className={itemLink}>
            <Button className={button} variant="tertiary" icon="arrow-right">
              {/* {t('cmsContent.partners.readMore')} */}
              Read more
            </Button>
          </div>
        )}
      </div>
    );
  };

  if (link) {
    return <Link href={link}>{content()}</Link>;
  }

  return content();
};

export default BlogPostItem;

import type { TFunction } from 'i18next';

import { PageData, PageProps } from '../services/page';



export interface ServerStore {
  pageProps: PageProps;
  pageData: PageData;
  t: TFunction<any, any>;
}

const createStore = () => {
  let data: ServerStore = {} as ServerStore;

  const setPageProps = (props: PageProps) => {
    data.pageProps = props;
  };

  const getPageProps = () => {
    return data.pageProps;
  };

  const setPageData = (pageData: PageData) => {
    data.pageData = pageData;
  };

  const getPageData = () => {
    return data.pageData;
  };

  const setTranslation = (t: TFunction<any, any>) => {
    data.t = t;
  };

  const getTranslation = () => {
    return data.t;
  };

  return { setPageProps, getPageProps, setPageData, getPageData, setTranslation, getTranslation };
};

export const store = createStore();

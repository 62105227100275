'use client';
import { Cms_EditorNodeData } from '@arpia-pt-frontends/lib/services/cms';
import { openInNewTab } from '@arpia-pt-frontends/lib/utils';
import { useRouter } from 'next/navigation';

import CmsEditorNode from './EditorNode';

interface EditorNestedLinkProps {
  data: Cms_EditorNodeData;
}

const EditorNestedLink: React.FC<EditorNestedLinkProps> = ({ data }) => {
  const element = data as Cms_EditorNodeData;
  const { push } = useRouter();

  const getLinkChild = (child: any) => {
    if (child.type === 'a') {
      return { ...child, type: 'span' };
    }

    return child;
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const url = element._url || '/';

    if (element.data.openInNewTab) {
      openInNewTab(url);

      return;
    }

    if (element.data.type === 'INTERNAL') {
      push(url);

      return;
    }

    window.location.href = url;
  };

  return (
    <button className="ps-editor-link" type="button" onClick={handleClick}>
      {element.children?.map((child: any, index: number) => (
        <CmsEditorNode data={getLinkChild(child)} key={index} />
      ))}
    </button>
  );
};

export default EditorNestedLink;

'use client';

import React from 'react';

import type { Cms_FlyoutMenu, Cms_SitemapTreeItem } from '@arpia-pt-frontends/lib/services/cms';
import { cx } from '@arpia-pt-frontends/styled-system/css';
import { mainNavigationItem } from '@arpia-pt-frontends/styled-system-web/recipes';
import { CmsNavLink } from '@arpia-pt-frontends/ui/cmsLink';
import { Icon } from '@arpia-pt-frontends/ui/icon';
import * as NavRx from '@radix-ui/react-navigation-menu';

import { FlyoutMenu } from '../flyoutMenu';

// const navItem = css({
//   pos: 'relative',
//   whiteSpace: 'nowrap',
//   display: 'flex',
//   alignItems: 'center'
// });

// const itemTrigger = css({
//   whiteSpace: 'nowrap',
//   display: 'flex',
//   alignItems: 'center',
//   gap: '6px',
//   outline: 'none',
//   userSelect: 'none',
//   pos: 'relative',
//   p: 3,
//   cursor: 'pointer',

//   '&[data-state="open"]': {
//     '& .ps-nav-item-line': {
//       w: 'calc(100% - 24px)'
//     }
//   }
// });

// const labelx = cva({
//   base: {
//     fontSize: '18px',
//     lineHeight: '18px'
//   },
//   variants: {
//     isActive: {
//       true: {
//         color: 'primary'
//       }
//     }
//   }
// });

// const navIcon = css({
//   w: 3,
//   h: 3,
//   transform: 'translateY(-1.5px)'
// });

// const line = css({
//   pos: 'absolute',
//   bottom: '7px',
//   w: 0,
//   h: '1px',
//   bg: 'black',
//   display: 'inline-block',
//   transformOrigin: 'left',
//   transition: 'width 0.2s ease-in-out',
//   _groupHover: {
//     width: 'calc(100% - 24px)'
//   }
// });

interface NavItemContentProps {
  data: Cms_SitemapTreeItem;
  hasChildren: boolean;
  activeItemId?: number;
  flyoutMenu?: Cms_FlyoutMenu;
}

const NavItemContent: React.FC<NavItemContentProps> = ({
  data,
  hasChildren,
  activeItemId,
  flyoutMenu
}) => {
  const { itemTrigger, line, navIcon } = mainNavigationItem();
  if ((data.type === 'internal' || data.type === 'directory') && hasChildren) {
    return (
      <>
        <NavRx.Trigger className={cx('group', itemTrigger)} value={data.id}>
          <span className={mainNavigationItem({ isActive: data.id === activeItemId }).label}>
            {data.title}
          </span>
          <Icon i="angle-down-circle" size="small" className={navIcon} />
          <span className={line} />
        </NavRx.Trigger>
        <FlyoutMenu forceMount data={data} flyoutMenu={flyoutMenu} activeItemId={activeItemId} />
      </>
    );
  }

  return (
    <CmsNavLink navItem={data} className={cx('group', itemTrigger)}>
      <span className={mainNavigationItem({ isActive: data.id === activeItemId }).label}>
        {data.title}
      </span>
    </CmsNavLink>
  );
};

interface NavitemProps {
  data: Cms_SitemapTreeItem;
  flyoutMenu?: Cms_FlyoutMenu;
  activeItemId?: number;
}

const NavItem = React.forwardRef<HTMLLIElement, NavitemProps>((props, ref) => {
  const { navItem } = mainNavigationItem();
  const { data, flyoutMenu, activeItemId, ...restProps } = props;
  const hasChildren =
    data.children.length > 0 &&
    data.children.filter(item => item.active && item.include.includes('header')).length > 0;

  return (
    <NavRx.Item ref={ref} className={navItem} {...restProps}>
      <NavItemContent
        data={data}
        hasChildren={hasChildren}
        activeItemId={activeItemId}
        flyoutMenu={flyoutMenu}
      />
    </NavRx.Item>
  );
});
NavItem.displayName = 'NavItem';

export { NavItem, NavItemContent };
export type { NavItemContentProps, NavitemProps };

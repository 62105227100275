'use client';

import React, { useCallback, useMemo, useState } from 'react';

import { COOKIE_BANNER_DURATION, COOKIE_BANNER_KEY } from '@arpia-pt-frontends/lib/constants';
import { useIsMounted, usePageProps } from '@arpia-pt-frontends/lib/hooks';
import { CookiePolicy } from '@arpia-pt-frontends/lib/types';
import { base64ToObject, objectToBase64 } from '@arpia-pt-frontends/lib/utils';
import { css, cva } from '@arpia-pt-frontends/styled-system/css';
import { Flex } from '@arpia-pt-frontends/styled-system/jsx';
import { Button } from '@arpia-pt-frontends/ui/button';
import { Checkbox } from '@arpia-pt-frontends/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogTitleDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@arpia-pt-frontends/ui/dialog';
import { Form, FormField } from '@arpia-pt-frontends/ui/form';
import { Icon } from '@arpia-pt-frontends/ui/icon';
import { Text } from '@arpia-pt-frontends/ui/text';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { isEqual, keys, omit } from 'lodash';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { z } from 'zod';

import { useTranslation } from '@/services/i18n';

type FetchStatus = 'idle' | 'loading' | 'error' | 'success';

// replace later with appropriate values
const CHECKBOX_1 = 'nescessary';
const CHECKBOX_2 = 'performance';

const dialogContent = css({ sm: { maxW: '766px' } });

const line = css({
  w: '100%',
  h: '1px',
  bg: 'gray.200',
  mt: 8,
  mb: 8
});

const cookieInfo = css({
  fontWeight: '400',
  mb: 8
});

const infoText = css({
  textStyle: 'block-xs',
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap'
});

const checkTitle = css({
  wordWrap: 'break-word',
  textStyle: 'block-sm',
  lineHeight: 'normal',
  fontWeight: '400'
});

const checkText = css({
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  mt: 4,
  ml: 6
});

const cookieIcon = css({
  pos: 'absolute',
  left: 'auto',
  right: 8,
  w: 6,
  h: 6,
  color: 'primary'
});

const footer = cva({
  base: {
    mt: 8
  },
  variants: {
    isManageCookies: {
      true: {
        mt: 0
      }
    }
  }
});

const cookieBtn = css({
  cursor: 'pointer',
  _hover: {
    color: 'primary'
  }
});

const cookieInfoDate = css({
  fontWeight: '500'
});

interface CookieValues {
  date: string;
  nescessary: boolean;
  performance: boolean;
}

interface Props {
  isSettings?: boolean;
  privacyPolicyPath?: string;
}
const CookieBanner: React.FC<Props> = ({ isSettings, privacyPolicyPath }) => {
  const {
    params: { tenant }
  } = usePageProps();

  const [isSettingOpen, setSettingOpen] = useState(false);
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [isManageCookies, setIsManageCookies] = useState<boolean>(false);
  const { t } = useTranslation();
  const router = useRouter();
  const pathname = usePathname();
  const isMounted = useIsMounted();

  const cookieValue = Cookies.get(COOKIE_BANNER_KEY);
  const isCookieSet = Boolean(cookieValue);
  const cookieValues = useMemo(() => {
    if (!isCookieSet) return null;

    let cookie = null;
    if (isCookieSet) {
      try {
        cookie = base64ToObject<CookieValues>(cookieValue as string);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error parsing cookie, will remove...', e);
        Cookies.remove(COOKIE_BANNER_KEY);
      }
    }

    return cookie;
  }, [cookieValue, isCookieSet]);

  const formSchema = isManageCookies
    ? z
        .object({
          [CHECKBOX_1]: z.literal(true),
          [CHECKBOX_2]: z.boolean()
        })
        .refine(d => d[CHECKBOX_1] || d[CHECKBOX_2], {
          message: t('cookies.banner.selection'),
          path: [CHECKBOX_1, CHECKBOX_2]
        })
    : z.object({});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: isSettings
      ? {
          nescessary: true,
          performance: cookieValues?.performance === false ? false : true
        }
      : {
          nescessary: true,
          performance: true
        }
  });

  const { watch } = form;
  const watchAllFields = watch();

  const parseTenant = useCallback(
    (ten: string) => {
      if (process.env.APP_ENV === 'prod') {
        if (ten === 'phoenix') {
          return `.${tenant}-systems.ch`;
        }
      }

      return undefined;
    },
    [tenant]
  );

  const setCookie = useCallback(
    (value: string) => {
      Cookies.set(COOKIE_BANNER_KEY, value, {
        expires: COOKIE_BANNER_DURATION,
        domain: parseTenant(tenant)
      });
    },
    [parseTenant, tenant]
  );

  // TODO: Check deny case functionality

  const handleCookieSettings = () => {
    setIsManageCookies(true);
    setSettingOpen(true);
  };

  const removeAnalyticsCookies = () => {
    const all = Cookies.get();
    (window as any).dataLayer = undefined;
    keys(all).forEach(key => {
      if (key.startsWith('_ga') || key.startsWith('_gid') || key.startsWith('_gat')) {
        Cookies.remove(key, { domain: '.phoenix-systems.local', path: '/' });
        Cookies.remove(key, { domain: '.phoenix-systems.ch', path: '/' });
      }
      if (key === 'ar_debug') {
        Cookies.remove(key, { domain: '.region1.google-analytics.com', path: '/' });
      }
    });
  };

  const onSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      setStatus('loading');

      const cookieConf: CookiePolicy = {
        date: new Date().toISOString(),
        nescessary: true
      };

      if (!isManageCookies) {
        cookieConf.performance = true;
      } else {
        cookieConf.performance = (values as any).performance;
      }

      const cookieStr = objectToBase64(cookieConf);
      setCookie(cookieStr);
      setStatus('success');

      if (isSettings) {
        setSettingOpen(false);
      }

      if (!cookieConf.performance) {
        removeAnalyticsCookies();
      }

      router.refresh();
    },
    [isManageCookies, setCookie, isSettings, router]
  );

  const formFields = (
    <>
      <FormField
        name={CHECKBOX_1}
        control={form.control}
        render={({ field }) => (
          <>
            <Checkbox
              {...field}
              checked
              // onCheckedChange={checked => field.onChange(checked)}
              value={String(field.value)}
              className={css({ pointerEvents: 'none' })}
            >
              <Text variant="teaser" className={checkTitle}>
                {t('cookies.banner.checkbox.one.title')}
              </Text>
            </Checkbox>
            <Text variant="teaser" className={checkText}>
              {t('cookies.banner.checkbox.one')}
            </Text>
          </>
        )}
      />
      <div className={line} />
      <FormField
        name={CHECKBOX_2}
        control={form.control}
        render={({ field }) => (
          <>
            <Checkbox
              {...field}
              onCheckedChange={checked => field.onChange(checked)}
              checked={field.value}
              value={String(field.value)}
            >
              <Text variant="teaser" className={checkTitle}>
                {t('cookies.banner.checkbox.two.title')}
              </Text>
            </Checkbox>
            <Text variant="teaser" className={checkText}>
              {t('cookies.banner.checkbox.two')}
            </Text>
          </>
        )}
      />
    </>
  );
  if (!isMounted) {
    return null;
  }

  if (isSettings) {
    // eslint-disable-next-line no-alert
    return (
      <>
        <Dialog open={isSettingOpen}>
          <DialogContent
            className={dialogContent}
            isCloseBtnShown={false}
            onPointerDownOutside={e => e.preventDefault()}
            onOpenAutoFocus={e => e.preventDefault()}
          >
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <DialogHeader>
                  <DialogTitle>{t('cookies.banner.editTitle')}</DialogTitle>
                  <Icon i="cookie" className={cookieIcon} />
                </DialogHeader>
                <DialogTitleDescription asChild>
                  <div>
                    <Text as="span" className={infoText}>
                      <Trans
                        i18nKey="cookies.banner.checkbox.text.two"
                        components={{
                          1: privacyPolicyPath ? (
                            <Link
                              href={privacyPolicyPath}
                              rel="noopener noreferrer"
                              target="_blank"
                            />
                          ) : (
                            <span />
                          )
                        }}
                      />
                    </Text>

                    <Flex flexDir="column">
                      <div className={line} />
                      {cookieValues?.date && (
                        <Text variant="block-sm" className={cookieInfo}>
                          Cookie configuration set on{' '}
                          <span className={cookieInfoDate}>
                            {dayjs(cookieValues?.date).format('DD. MMMM YYYY, HH:mm')}
                          </span>
                          .
                        </Text>
                      )}
                      {formFields}
                      <div className={line} />
                    </Flex>
                  </div>
                </DialogTitleDescription>
                <div className={footer({ isManageCookies })}>
                  <DialogFooter className={css({ mt: 0 })}>
                    {isManageCookies ? (
                      <Button
                        type="button"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          setIsManageCookies(false);
                          setSettingOpen(false);
                        }}
                      >
                        {t('cookies.banner.close')}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="secondary"
                        size="small"
                        onClick={() => setIsManageCookies(true)}
                      >
                        {t('cookies.banner.btnManageCookies')}
                      </Button>
                    )}
                    {isManageCookies && (
                      <Button
                        type="submit"
                        variant="primary"
                        icon="arrow-right"
                        size="small"
                        loading={status === 'loading'}
                        disabled={
                          cookieValues
                            ? isEqual(form.getValues(), omit(cookieValues, 'date'))
                            : false
                        }
                      >
                        {t('cookies.banner.update')}
                      </Button>
                    )}
                  </DialogFooter>
                </div>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
        <button className={cookieBtn} onClick={handleCookieSettings}>
          Cookies
        </button>
      </>
    );
  }

  return (
    <Dialog open={cookieValues === null && pathname !== privacyPolicyPath}>
      <DialogContent
        className={dialogContent}
        isCloseBtnShown={false}
        onPointerDownOutside={e => e.preventDefault()}
        onOpenAutoFocus={e => e.preventDefault()}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>{t('cookies.banner.title')}</DialogTitle>
              <Icon i="cookie" className={cookieIcon} />
            </DialogHeader>
            <DialogTitleDescription asChild>
              <div>
                {!isManageCookies && (
                  <>
                    <Text as="span" className={infoText}>
                      {t('cookies.banner.text')}
                    </Text>
                  </>
                )}
                {isManageCookies && (
                  <>
                    <Text as="span" className={infoText}>
                      <Trans
                        i18nKey="cookies.banner.checkbox.text.two"
                        components={{
                          1: privacyPolicyPath ? (
                            <Link
                              href={privacyPolicyPath}
                              rel="noopener noreferrer"
                              target="_blank"
                            />
                          ) : (
                            <span />
                          )
                        }}
                      />
                    </Text>
                    <Flex flexDir="column">
                      <div className={line} />
                      {formFields}
                      <div className={line} />
                    </Flex>
                  </>
                )}
              </div>
            </DialogTitleDescription>
            <div className={footer({ isManageCookies })}>
              <DialogFooter className={css({ mt: 0 })}>
                <Button
                  type="button"
                  variant="secondary"
                  size="small"
                  onClick={() => setIsManageCookies(true)}
                  disabled={isManageCookies === true}
                >
                  {t('cookies.banner.btnManageCookies')}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  icon="arrow-right"
                  size="small"
                  loading={status === 'loading'}
                  disabled={
                    status === 'loading' ||
                    (isManageCookies === true &&
                      (!(CHECKBOX_1 in watchAllFields) || !watchAllFields[CHECKBOX_1]) &&
                      (!(CHECKBOX_2 in watchAllFields) || !watchAllFields[CHECKBOX_2]))
                  }
                >
                  {isManageCookies === true
                    ? t('cookies.banner.btnAllow')
                    : t('cookies.banner.btnAllowAll')}
                </Button>
              </DialogFooter>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CookieBanner;
